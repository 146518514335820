import { render, staticRenderFns } from "./ShellDoughuntChart.vue?vue&type=template&id=31884a57&scoped=true&lang=pug&"
import script from "./ShellDoughuntChart.vue?vue&type=script&lang=js&"
export * from "./ShellDoughuntChart.vue?vue&type=script&lang=js&"
import style0 from "./ShellDoughuntChart.vue?vue&type=style&index=0&id=31884a57&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31884a57",
  null
  
)

export default component.exports